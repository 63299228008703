<template>
  <base-modal v-model:open="open" hide-footer>
    <template v-slot:button>
      <div
        class="card card-hover cursor-pointer"
        :class="{ active: template.selected && !hideSelection }"
      >
        <div class="relative w-full" style="padding-top: 100%;">
          <img
        class="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
        :src="template.thumbnail"
          />
        </div>
      </div>
    </template>

    <div class="card-body pt-4 flex justify-center items-center">
      <img
        class="rounded-lg w-full"
        :src="template.thumbnail"
      />
    </div>

    <div class="card-footer flex justify-end space-x-3">
      <button class="btn" @click.prevent="close">
        {{ $t('action.cancel') }}
      </button>
      <button
        class="btn border border-red-600 text-red-600"
        @click="toogle(template.id)"
        v-if="template.selected"
      >
        {{ $t('page.templates.acltion.forget_template') }}
      </button>
      <button
        class="btn btn-primary"
        @click="toogle(template.id)"
        v-else
      >
        {{ $t('page.templates.acltion.use_template') }}
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },
  props: {
    template: {
      type: Object,
      default: () => ({})
    },
    hideSelection: {
      type: Boolean,
      default: false
    },
  },

  emits: ['onToogle'],

  setup (props, { emit }) {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)
    
    const toogle = (id: string) => {
      emit('onToogle', id)
      close()
    }

    return {
      open,
      close,
      toogle
    }
  }
})
</script>

<style scoped>
@layer components {
  .active {
    @apply ring-4 ring-indigo-500;
  }
}
</style>
