import { useProfile } from '@/composables/profile/useProfile'
import { useProfileService } from '@/services/firestore/profile'
import { computed, unref } from 'vue'
import { useStore } from 'vuex'

const MAX_ALLOWED = 6

export const useTemplates = () => {
  const store = useStore()

  const { updateProfile } = useProfileService()

  const templateFilters = {
    partnerTemplateType: 'customTemplate', // 'customTemplate' | 'template'
    format: 'Instagram',
    limit: 30
  }

  const {
    profile
  } = useProfile()

  const isLoaded = computed(() => {
    return store.state.templates.isLoaded
  })

  const isLoading = computed(() => {
    return store.state.templates.isLoading
  })

  const selected = computed(() => {
    const {
      templates = []
    } = profile.value

    return templates.map((template: { id: string, thumbnail: string }) => ({
      ...template,
      selected: true,
      isKit: template.id.startsWith("kit_"),
    }))
  })

  const templates = computed(() => {
    const templates = store.state.templates.templates
    const selectedTemplates = unref(selected).map((template: { id: string }) => template.id)

    return templates.map((template: { id: string, thumbnail: string }) => ({
      ...template,
      selected: selectedTemplates.includes(template.id)
    }))
  })

  const fetchTemplates = (filter?: {}) => {
    const count = store.state.templates.count
    if (count === 0 || count > templates.value.length) {
      store.dispatch('templates/fetch', {...templateFilters, ...filter})
    }
  }

  const toogle = async (templateId: string) => {
    let currentSelecteds = unref(selected)
    const index = currentSelecteds.findIndex((template: { id: string }) => template.id === templateId)
    
    if (index >= 0) {
      currentSelecteds.splice(index, 1)
    } else {
      if (currentSelecteds.length >= MAX_ALLOWED) {
        return
      }
      const template = unref(templates)
        .find((template: { id: string }) => template.id === templateId)
      const { selected, ...templateData } = template
      currentSelecteds = [...currentSelecteds, {...templateData, selected: true, isKit: templateId.startsWith("kit_")}]
    }

    await updateProfile({ templates: currentSelecteds })
  }

  return {
    isLoaded,
    isLoading,
    templates,
    selected,
    fetchTemplates,
    toogle
  }
}